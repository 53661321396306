.App {
  text-align: center;
}

.App-header {
   min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

#Header {
  background:#24292e;
}

#footer{
  background:#1c2d3d;
  padding: 2em;
}

footer .text{
  color:#FFF;
  font-size:12px;
}

.MuiCard-root{
  margin: 5px;
}

.searchSection{
  margin:2em;
}

.searchTitle{
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
}
